.trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.logo {
  height: 32px;
  margin: 16px;
}

#root {
  width: 100%;
  height: 100%;
}

.site-layout .site-layout-background {
  background: #fff;
}
#components-layout .logo {
  width: 120px;
  height: 31px;
  background: #333;
  border-radius: 6px;
  margin: 16px 28px 16px 0;
  float: left;
}

.profileTopBar {
  border: #333 1px solid;
  height: 30px;
}
.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
  padding: 4px 11px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}

[data-theme="dark"] .editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #434343;
}
.tdStyle {
  padding: 8px;
  text-align: center;
  border: 1px solid #dddddd;
}
.tdDescriptionStyle {
  padding: 10px;
  width: 300px;
  text-align: left;
  border: 1px solid #dddddd;
}
.descritionDetail {
  color: gray;
}
.thStyle {
  padding: 10px;
  background-color: #fafafa;
  text-align: center;
  border: 1px solid #dddddd;
}
@media print {
  .thStyle {
    padding: 10px;
    background-color: #fafafa;
    text-align: center;
    border: 1px solid #dddddd;
    -webkit-print-color-adjust: exact;
  }
  .tdStyle {
    padding: 8px;
    text-align: center;
    border: 1px solid #dddddd;
    -webkit-print-color-adjust: exact;
  }
  .tdDescriptionStyle {
    padding: 10px;
    width: 300px;
    text-align: left;
    border: 1px solid #dddddd;
    -webkit-print-color-adjust: exact;
  }
  .descritionDetail {
    color: gray;
    -webkit-print-color-adjust: exact;
  }
  .thStyle {
    padding: 10px;
    background-color: #dddddd;
    text-align: center;
    border: 1px solid #dddddd;
    -webkit-print-color-adjust: exact;
  }
}
.container {
  padding: 20px;
  color: red;
}
.divFooter {
  position: fixed;
  bottom: 0;
}

@media screen {
  div.divFooter {
    display: none;
  }
}
@media print {
  div.divFooter {
    position: fixed;
    bottom: 0;
    width: "100%";
  }
}

.site-description-item-profile-wrapper {
  margin-bottom: 7px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5715;
}

[data-theme="compact"] .site-description-item-profile-wrapper {
  font-size: 12px;
  line-height: 1.66667;
}

.ant-drawer-body p.site-description-item-profile-p {
  display: block;
  margin-bottom: 16px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  line-height: 1.5715;
}

[data-theme="compact"] .ant-drawer-body p.site-description-item-profile-p {
  font-size: 14px;
  line-height: 1.66667;
}

.site-description-item-profile-p-label {
  display: inline-block;
  margin-right: 8px;
  color: rgba(0, 0, 0, 0.85);
}
